import { SvgIcon } from "@mui/material";
import { type ComponentProps, useId } from "react";

export function MoneyIcon(props: ComponentProps<typeof SvgIcon>) {
  const clipPathId = useId();

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <g clipPath={`url(#${clipPathId})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 9C18 13.97 13.97 18 9 18C7.56 18 6.2 17.66 4.99 17.06C4.67 16.9 4.3 16.85 3.96 16.94L1.96 17.48C1.76 17.53 1.55 17.53 1.35 17.48C1.15 17.43 0.97 17.32 0.83 17.18C0.68 17.03 0.58 16.85 0.53 16.66C0.48 16.46 0.48 16.25 0.53 16.05L1.07 14.05C1.16 13.7 1.11 13.33 0.95 13.02C0.32 11.76 0 10.39 0 9C0 4.03 4.03 0 9 0C13.97 0 18 4.03 18 9ZM9 3.82C9.22 3.82 9.43 3.91 9.58 4.06C9.73 4.21 9.82 4.42 9.82 4.64C11.01 4.95 12 5.89 12 7.19C12 7.41 11.91 7.62 11.76 7.77C11.61 7.92 11.4 8.01 11.18 8.01C10.96 8.01 10.75 7.92 10.6 7.77C10.45 7.62 10.36 7.41 10.36 7.19C10.36 6.77 9.9 6.19 9 6.19C8.1 6.19 7.64 6.77 7.64 7.19C7.64 7.61 8.1 8.19 9 8.19C10.51 8.19 12 9.24 12 10.83C12 12.13 11.01 13.07 9.82 13.36C9.82 13.59 9.73 13.8 9.58 13.95C9.43 14.1 9.22 14.19 9 14.19C8.78 14.19 8.57 14.1 8.42 13.95C8.27 13.8 8.18 13.59 8.18 13.37C6.99 13.06 6 12.12 6 10.82C6 10.6 6.09 10.39 6.24 10.24C6.39 10.09 6.6 10 6.82 10C7.04 10 7.25 10.09 7.4 10.24C7.55 10.39 7.64 10.6 7.64 10.82C7.64 11.24 8.1 11.82 9 11.82C9.9 11.82 10.36 11.24 10.36 10.82C10.36 10.4 9.9 9.82 9 9.82C7.49 9.82 6 8.77 6 7.18C6 5.88 6.99 4.95 8.18 4.65C8.18 4.42 8.27 4.21 8.42 4.06C8.57 3.91 8.78 3.82 9 3.82Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
